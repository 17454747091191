import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import * as en from "./static/locales/en/translation.json";
import * as enFeedback from "./static/locales/en/feedback.json";
import * as ja from "./static/locales/ja/translation.json";
import * as jaFeedback from "./static/locales/ja/feedback.json";

i18n.use(initReactI18next).init({
  // Normally, in React app i18next-http-backend is used, but it doesn't work in Gatsby's static mode, so translations must be loaded directly
  resources: {
    en: {
      translation: en,
      feedback: enFeedback,
    },
    ja: {
      translation: ja,
      feedback: jaFeedback,
    },
  },
  lng: process.env.GATSBY_I18N_LANG ?? "en",
  load: "languageOnly",
  debug: false,
  returnEmptyString: false,
  interpolation: {
    escapeValue: false,
  },
  nsSeparator: false,
  keySeparator: false,
  react: {
    transKeepBasicHtmlNodesFor: ["wbr", "br", "strong", "i", "p"], // It allows to use <wbr/> tag in translation strings. Only in <Trans/>, not for t()
    //   useSuspense: false,
  },
});

export default i18n;
