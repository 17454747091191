import { Theme, responsiveFontSizes } from "@mui/material";
import { createTheme, ComponentsOverrides } from "@mui/material/styles";
import shadows from "./shadows";
import { light, dark } from "./palette";

const getTheme = (mode: string, themeToggler: () => void): Theme =>
  responsiveFontSizes(
    createTheme({
      palette: mode === "light" ? light : dark,
      shadows: shadows(mode),
      typography: {
        fontFamily: "Lexend, sans-serif",
        h1: { wordBreak: "keep-all" }, // It disables line breaks by word for Japanese (as browser can't determine individual Japanese words and adds linebreaks in wrong places). Add \u200B to a place where line break is allowed
        h2: { wordBreak: "keep-all" },
        h3: { wordBreak: "keep-all" },
        h4: { wordBreak: "keep-all" },
        h5: { wordBreak: "keep-all" },
        h6: { wordBreak: "keep-all" },
        button: {
          textTransform: "none",
          fontWeight: "medium" as React.CSSProperties["fontWeight"],
        },
      },
      zIndex: {
        appBar: 1200,
        drawer: 1300,
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              fontWeight: 400,
              borderRadius: 5,
              paddingTop: 10,
              paddingBottom: 10,
            },
            containedSecondary: mode === "light" ? { color: "white" } : {},
          } as ComponentsOverrides["MuiButton"],
        },
        MuiInputBase: {
          styleOverrides: {
            root: {
              borderRadius: 5,
            },
          } as ComponentsOverrides["MuiInputBase"],
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              borderRadius: 5,
            },
            input: {
              borderRadius: 5,
            },
          } as ComponentsOverrides["MuiOutlinedInput"],
        },
        MuiCard: {
          styleOverrides: {
            root: {
              borderRadius: 8,
            },
          } as ComponentsOverrides["MuiCard"],
        },
      },
      themeToggler,
    }),
  );

export default getTheme;
