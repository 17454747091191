import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import "../../i18n";

import Page from "../../src/components/Page";
import { LiveChatWidget } from "@livechat/widget-react";

import favicon from "./favicon.png";
import previewEn from "./preview_en.jpg";
import previewJa from "./preview_ja.jpg";
import { getI18n } from "react-i18next";

// TODO uncomment imports when packages are installed
// import 'react-lazy-load-image-component/src/effects/blur.css';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import 'react-image-lightbox/style.css';
// import 'aos/dist/aos.css';

const DESCRIPTION = new Map([
  [
    "ja",
    "国内初！アプリのサブスクリプション解約を未然に自動で防ぐ営業支援システムsubgrowを提供、国内外で3桁以上のスマホアプリを開発してきた株式会社ボグノフの公式ウェブサイトです。",
  ],
  [
    "en",
    "This is the official website of Bogunov inc., which provides a sales support system, subgrow, that automatically prevents subscription cancellations of apps first in Japan, and has developed more than 100 apps in Japan and overseas.",
  ],
]);

const URL = new Map([
  ["ja", "https://bogunov.co.jp/"],
  ["en", "https://bogunov.uk/"],
]);

const LOCALE = new Map([
  ["ja", "ja_JP"],
  ["en", "en_US"],
]);

export default function TopLayout(props) {
  const i18n = getI18n();
  // const [liveChatGroup, setLiveChatGroup] = useState("1"); // defaults to en
  //
  // useEffect(() => {
  //   setLiveChatGroup(i18n.language.startsWith("ja") ? "2" : "1");
  // }, [i18n.language]);

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="shortcut icon" href={favicon} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <title>Bogunov inc.</title>
        <meta name="theme-color" content="#ffffff" />
        <meta name="description" content={DESCRIPTION.get(i18n.language)} />
        <meta
          name="robots"
          content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <meta property="og:locale" content={LOCALE.get(i18n.language)} />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={i18n.language === "en" ? previewEn : previewJa}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:image"
          content={i18n.language === "en" ? previewEn : previewJa}
        />
        <meta property="og:title" content="Bogunov inc." />
        <meta
          property="og:description"
          content={DESCRIPTION.get(i18n.language)}
        />
        <meta property="og:url" content={URL.get(i18n.language)} />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          rel="preload"
          as="style"
          href="https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;500;600;700&display=swap"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;500;600;700&display=swap"
          rel="stylesheet"
          media="print"
          onload="this.media='all'"
        />
      </Helmet>
      <Page>{props.children}</Page>
      <LiveChatWidget
        license="14013033"
        visibility="minimized"
        // group={liveChatGroup} Can be used to switch chat lang, but not available on current plan
      />
    </React.Fragment>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node,
};
